import './App.css';
import { useState, useEffect, useRef } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-resizable/css/styles.css';
import './App.css';

function App() {
    const [inputUrl, setInputUrl] = useState('');
    const [screenshotUrl, setScreenshotUrl] = useState('');
    const [apiResponseReceived, setApiResponseReceived] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [frequency, setFrequency] = useState('');
    const frequencies = ["Every five minutes", "Every half hour", "Every hour", "Every 6 hours", "Every 12 hours", "Daily", "Weekly", "Monthly"];
    const [isListboxVisible, setIsListboxVisible] = useState(false);
    const listboxRef = useRef(null);
    const [crop, setCrop] = useState({
        unit: 'px',
        width: 200,
        height: 200,
        x: 200,
        y: 200,
        aspect: 1 // You can remove the aspect ratio if you don't want to enforce one
    });

    const handleClickOutside = (event) => {
        if (listboxRef.current && !listboxRef.current.contains(event.target)) {
            setIsListboxVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleListboxVisibility = () => {
        setIsListboxVisible(!isListboxVisible);
    };

    const isEmailEntered = email.trim() !== '';

    const isUrlEntered = inputUrl.trim() !== '';

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
    const [displayedImageDimensions, setDisplayedImageDimensions] = useState({ width: 0, height: 0 });

    const handleImageLoad = (event) => {
        // When the image is loaded, we set the original (natural) dimensions
        setOriginalDimensions({
            width: event.target.naturalWidth,
            height: event.target.naturalHeight,
        });

        // We also set the displayed dimensions
        setDisplayedImageDimensions({
            width: event.target.offsetWidth,
            height: event.target.offsetHeight,
        });
    };

    const handleWatchButtonClick = async () => {
        const apiUrl = `http://localhost:8000/start-watching`;
        if (originalDimensions.width === 0 || displayedImageDimensions.width === 0) {
            console.error("Image dimensions not set yet");
            return;
        }

        // Calculate the scale factors
        const scaleX = originalDimensions.width / displayedImageDimensions.width;
        const scaleY = originalDimensions.height / displayedImageDimensions.height;

        // Adjust crop parameters to the natural image size
        const adjustedCrop = {
            x: Math.round(crop.x * scaleX),
            y: Math.round(crop.y * scaleY),
            width: Math.round(crop.width * scaleX),
            height: Math.round(crop.height * scaleY)
        };

        const queryParams = new URLSearchParams({
            url: inputUrl,
            x: adjustedCrop.x,
            y: adjustedCrop.y,
            width: adjustedCrop.width,
            height: adjustedCrop.height,
            frequency: frequency,
            email: email
        });

        try {
            const response = await fetch(`${apiUrl}?${queryParams}`, { method: 'POST' });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            alert(JSON.stringify(data, null, 2));
            console.log('X:', crop.x, 'Y:', crop.y, 'Width:', crop.width, 'Height:', crop.height);
            // console.log(data); // Or handle data as needed
        } catch (error) {
            console.error('Error making the request:', error);
        }
    };

    const handleInputChange = (event) => {
        setInputUrl(event.target.value);
        setApiResponseReceived(false);
    };

    const handleGoClick = async () => {
        setIsLoading(true);
        try {
            const encodedUrl = encodeURIComponent(inputUrl);
            const apiUrl = `https://api.screenshotone.com/take?access_key=${process.env.REACT_APP_SCREENSHOT_API_KEY}&url=${encodedUrl}&viewport_width=1440&viewport_height=900&device_scale_factor=1&image_quality=80&format=jpg&block_ads=true&block_cookie_banners=true&block_trackers=true&block_banners_by_heuristics=false&delay=0&timeout=60&full_page=true`;

            const response = await fetch(apiUrl);

            if (response.ok) {
                const imageBlob = await response.blob();
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setScreenshotUrl(imageObjectURL);
                setApiResponseReceived(true);
            } else {
                throw new Error('Screenshot API responded with an error.');
            }
        } catch (error) {
            console.error('Error taking screenshot:', error);
            setApiResponseReceived(false);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            if (screenshotUrl) {
                URL.revokeObjectURL(screenshotUrl);
            }
        };
    }, [screenshotUrl]);

    return (
        <div className="App">
            <header className="App-header">
                <h1>Stay up to date with changes on your favorite websites!</h1>
                <div className="input-group">
                    <input
                        type="text"
                        className="url-input"
                        placeholder="Enter a website: e.g. www.google.com"
                        onChange={handleInputChange}
                        value={inputUrl}
                    />
                    <button
                            className={`go-button ${!isUrlEntered ? 'go-button-disabled' : ''}`}
                            onClick={handleGoClick}
                            disabled={!isUrlEntered}
                    >Go
                    </button>
                </div>
                <div className="website-window">
                    {!apiResponseReceived && !isLoading && (
                        <p className="description">A screenshot of your website will appear here</p>
                    )}
                    {isLoading && (
                        <div className="center-spinner">
                            <div className="spinner"></div>
                        </div>
                    )}
                    {apiResponseReceived && (
                        <ReactCrop crop={crop} onChange={c => setCrop(c)}>
                            <img src={screenshotUrl} alt={"Screenshot"} onLoad={handleImageLoad}/>
                        </ReactCrop>
                    )}
                </div>
                <div className="input-group">
                    <input
                        type="email"
                        className="email-input"
                        placeholder="Enter your email"
                        onChange={handleEmailChange}
                        value={email}
                    />
                    <button
                        className={`watch-button ${!isEmailEntered ? 'watch-button-disabled' : ''}`}
                        onClick={handleWatchButtonClick}
                        disabled={!isEmailEntered}
                    >
                        Start Watching
                    </button>
                    <div className="frequency-selection">
                        <button
                            className={`listbox-button ${!isEmailEntered ? 'listbox-button-disabled' : ''}`}
                            onClick={toggleListboxVisibility}
                            disabled={!isEmailEntered}
                        >
                            {frequency || 'Select Frequency'} ⌄
                        </button>
                        {isListboxVisible && (
                            <ul className={`listbox ${!isEmailEntered ? 'listbox-disabled' : ''}`} ref={listboxRef}>
                                {frequencies.map(frequencyOption => (
                                    <li
                                        key={frequencyOption}
                                        onClick={() => {
                                            setFrequency(frequencyOption);
                                            setIsListboxVisible(false); // Hide the listbox after selection
                                        }}
                                        className={frequency === frequencyOption ? 'selected' : ''}
                                    >
                                        {frequencyOption}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </header>
        </div>
    );
}

export default App;
